import im1 from "../../images/eComm.jpg";
import im2 from "../../images/domain.jpg";
import im3  from "../../images/digiMark.jpg";


// eslint-disable-next-line import/no-anonymous-default-export
export default [
 
  {title: "Our vision is to accelerate equity in the workplace and in society by lighting up pathways to career success for individuals from all backgrounds",
  url: im2,
   
 
    
  }, 
 
  {
    title: "Unlocking Digital Potential Together",
    url: im1,

  },
  {
    title: "Our mission is to close the talent gap by upskilling a diverse candidate pool to recast our clients’ workforces",
    url:im3,
   
  },

 
  
];