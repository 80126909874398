const ServiceDetails=[
    {
        id:"1",
        Image: require('../../images/training.jpg'),
        name: "Training ",
        link: '/trainings',
   
    },
    {
        id:"2",
        Image: require('../../images/job support.jpg'),
        name: "Job Support",
        link: "/jobsupport",
       
    },
    {
        id:"3",
       
        Image: require('../../images/digiMark.jpg'),
        name: "Digital Marketing",
        link: "/marketing",
   
    },
    {
        id:"4",
       
        Image: require('../../images/seo.jpg'),
        name: "C2C and W2 Service",
        link: "/employments",
   
    },
    
    {
        id:"5",
       
        Image: require('../../images/webDeve.jpg'),
        name: "Web Development",
        link: "/webdevelopment",
   
    },
    {
        id:"6",
       
        Image: require('../../images/images.jpeg'),
        name: "Software Development",
        link: "/softwaredevelopment",
   
    },
    {
        id:"7",
       
        Image: require('../../images/app.jpg'),
        name: "Mobile App Development",
        link: "/mobileappdevelopment",
   
    },
    {
        id:"8",
       
        Image: require('../../images/eComm.jpg'),
        name: "E-Commerce Setup",
        link:"/ecommerce",
   
    },
]
export default ServiceDetails;

